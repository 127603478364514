import React, { createContext, useContext } from "react";
import useOnlineIndicator from "~/hooks/useOnlineIndicator";
import { api, type RouterOutputs } from "~/utils/api";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";
interface ListContextType {
  lists: RouterOutputs["list"]["listsList"];
  handleRefetch: () => void;
}
const ListsContext = createContext<ListContextType>({
  lists: [],
  handleRefetch: () => null
});
export function useLists() {
  return useContext(ListsContext);
}
export function ListsProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    activeTeamAccount
  } = useTeamAccount();
  const logger = useLogger();
  const isOnline = useOnlineIndicator();
  const {
    data: lists,
    refetch
  } = api.list.listsList.useQuery({
    teamAccountId: activeTeamAccount.id
  }, {
    enabled: isOnline
  });
  const handleRefetch = () => {
    refetch().catch(err => logger.error(err, "ListsProvider.tsx"));
  };
  if (!lists) return null;
  return <ListsContext.Provider value={{
    lists,
    handleRefetch
  }} data-sentry-element="unknown" data-sentry-component="ListsProvider" data-sentry-source-file="ListsProvider.tsx">
      {children}
    </ListsContext.Provider>;
}