import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppType } from "next/app";
import { useState, useEffect } from "react"; // Import useState and useEffect
import { api } from "~/utils/api";
import { Inter, Major_Mono_Display } from "next/font/google";
import Head from "next/head";
import { useDarkMode } from "~/hooks/useDarkMode";
import { ListsProvider } from "~/providers/ListsProvider";
import { LoggerProvider } from "~/providers/LoggerProvider";
import SidebarProvider from "~/providers/SidebarProvider";
import { TeamAccountProvider } from "~/providers/TeamAccountProvider";
import { TeamAccountUserProvider } from "~/providers/TeamAccountUserProvider";
import MobileScreen from "~/pages/mobileScreen";
import "~/styles/globals.css";
import ActivityTracker from "~/components/ActivityTracking";
import { env } from "~/env.mjs";
import Link from "next/link";
const inter = Inter({
  subsets: ["latin"],
  variable: "--inter"
});
const majorMonoDisplay = Major_Mono_Display({
  subsets: ["latin"],
  weight: "400",
  variable: "--major-mono-display"
});
const App: AppType<{
  session: Session | null;
}> = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}) => {
  useDarkMode();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  if (env.NEXT_PUBLIC_MAINTENANCE_MODE === "true") return <div className="w-full bg-white-500 flex  items-center h-screen justify-center">
        <div className="text-white text-2xl bg-black p-8 rounded-lg max-w-lg font-semibold font-mono">
          Hi 👋, OpenQ is currently under scheduled maintenance. Expected
          completion time is 6:00 a. m. GMT November 12. If you have any urgent
          queries, please contact us through our discord{" "}
          <Link href="https://discord.gg/Zt83ZswQ" className="underline text-blue-600">
            server
          </Link>
          .
        </div>
      </div>;
  return <div className={inter.variable.concat(" ").concat(majorMonoDisplay.variable)} data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>OpenQ DRM</title>
        <meta name="OpenQ DRM" content="width=device-width, initial-scale=1.0" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <link rel="icon" href="/openq-logo.png" />
        <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
      </Head>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>

      <SessionProvider session={session} data-sentry-element="SessionProvider" data-sentry-source-file="_app.tsx">
        <TeamAccountUserProvider data-sentry-element="TeamAccountUserProvider" data-sentry-source-file="_app.tsx">
          <ActivityTracker data-sentry-element="ActivityTracker" data-sentry-source-file="_app.tsx" />
          <LoggerProvider data-sentry-element="LoggerProvider" data-sentry-source-file="_app.tsx">
            <TeamAccountProvider data-sentry-element="TeamAccountProvider" data-sentry-source-file="_app.tsx">
              <ListsProvider data-sentry-element="ListsProvider" data-sentry-source-file="_app.tsx">
                <SidebarProvider data-sentry-element="SidebarProvider" data-sentry-source-file="_app.tsx">
                  {isMobile ? <MobileScreen /> : <Component {...pageProps} />}
                </SidebarProvider>
              </ListsProvider>
            </TeamAccountProvider>
          </LoggerProvider>
        </TeamAccountUserProvider>
      </SessionProvider>
    </div>;
};
export default api.withTRPC(App);