import Image from "next/image";
import { type ReactNode } from "react";
export default function HeroPage({
  children
}: {
  children: ReactNode;
}) {
  return <div className="flex h-screen w-full content-center items-center justify-center" data-sentry-component="HeroPage" data-sentry-source-file="HeroPage.tsx">
      <div className="h-screen w-1/2 bg-cover bg-right" style={{
      backgroundImage: "url(/hero.png)"
    }}>
        <Image className="absolute left-10 top-10" alt="OpenQ" width="143" height="39" src="/openq-text-logo-white.png" data-sentry-element="Image" data-sentry-source-file="HeroPage.tsx" />
      </div>
      {children}
    </div>;
}