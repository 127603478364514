import { createContext, useContext, useState } from "react";
type TToggleSidebar = (isOpen: boolean) => void;
const isSidebarOpenContext = createContext(false);
const toggleSidebarContext = createContext<TToggleSidebar | undefined>(undefined);
export function useIsSidebarOpen() {
  return useContext(isSidebarOpenContext);
}
export function useToggleSidebar() {
  return (useContext(toggleSidebarContext) as TToggleSidebar);
}
export default function SidebarProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = (isOpen?: boolean) => {
    setIsSidebarOpen(isCurrentlyOpen => {
      if (isOpen === undefined) {
        return !isCurrentlyOpen;
      }
      return isOpen;
    });
  };
  return <isSidebarOpenContext.Provider value={isSidebarOpen} data-sentry-element="unknown" data-sentry-component="SidebarProvider" data-sentry-source-file="SidebarProvider.tsx">
      <toggleSidebarContext.Provider value={toggleSidebar} data-sentry-element="unknown" data-sentry-source-file="SidebarProvider.tsx">
        {children}
      </toggleSidebarContext.Provider>
    </isSidebarOpenContext.Provider>;
}