import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { ChartPieIcon } from "@heroicons/react/24/outline";
import type {
  List,
  RepoEvaluation,
  ContactBatchCreation,
  GithubUrl,
} from "@openqlabs/drm-db";
import type { NextRouter } from "next/router";
import React from "react";
import { trpc } from "~/store/trpc";
import { RouterOutputs } from "~/utils/api";
import type { InfiniteData } from "@tanstack/react-query";
import { getUniqueArraySimple } from "@openqlabs/utils";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const downloadTable = async (
  activeHeaderMenu: string,
  teamAccountId: string,
  tableName?: string,
  listId?: string
) => {
  const type = activeHeaderMenu === "Teams" ? "repo" : "user";
  const contacts = await trpc.list.listExport.query({
    teamAccountId,
    listId,
    type,
  });
  if (contacts) {
    const blob = new Blob([contacts], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${tableName}_${activeHeaderMenu.toLowerCase()}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  }
};

export const downloadAsToml = async ({
  activeHeaderMenu,
  teamAccountId,
  teamName,
  tableName,
  listId,
  importedToml,
}: {
  activeHeaderMenu: string;
  teamAccountId: string;
  teamName: string;
  tableName?: string;
  listId?: string;
  importedToml?: string;
}) => {
  const type = activeHeaderMenu === "Teams" ? "repo" : "user";
  const contactsToml = await trpc.list.listExportToml.mutate({
    teamAccountId,
    listId,
    type,
    teamName,
    importedToml,
  });
  if (contactsToml) {
    const blob = new Blob([contactsToml], { type: "application/toml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${tableName}.toml`;
    a.click();
    URL.revokeObjectURL(url);
  }
};

export const getActiveWidth = (score: number) => {
  switch (score) {
    case 0:
      return "w-[12px]";
    case 1:
      return "w-[24px]";
    case 2:
      return "w-[36px]";
    case 3:
      return "w-[48px]";
    case 4:
      return "w-[60px]";
    case 5:
      return "w-[72px]";
    case 6:
      return "w-[84px]";
    case 7:
      return "w-[96px]";
    case 8:
      return "w-[108px]";
    case 9:
      return "w-[120px]";
    case 10:
      return "w-[132px]";
    default:
      return "w-[12px]";
  }
};

export const getActiveColor = (scoreType: string) => {
  switch (scoreType) {
    case "activity":
      return "bg-activity";
    case "popularity":
      return "bg-popularity";
    case "growth":
      return "bg-growth";
    case "reputation":
      return "bg-reputation";
  }
  return "";
};

export const getDependenciesText = (
  dependenciesFound: boolean,
  dependenciesSearched: string[] | undefined,
  type: "user" | "repo"
) => {
  if (!dependenciesFound || !dependenciesSearched) return null;

  return React.createElement(
    "div",
    null,
    `This  ${
      type === "user" ? "user" : "repository"
    } is utilizing a tracked dependency.`
  );
};

export const getSettingItems = (repoContactId: string, lists: List[]) => {
  return repoContactId
    ? lists.map((list) => {
        return {
          name: list.name,
          icon: ChartPieIcon,
          id: list.id,
        };
      })
    : [];
};

export const handleRefetchBasedOnUnfinishedEvals = (
  data?: InfiniteData<RouterOutputs["listContact"]["getContactsByListId"]>
) => {
  if (data) {
    const unfinishedEvals = data.pages.flatMap((page) =>
      page.items.flatMap((item) => item.waitingOnDepsEval ?? item.waitingOnEval)
    );

    const hasUnfinishedEvals = unfinishedEvals.includes(true);
    if (hasUnfinishedEvals) {
      return 1000 * 60 * 60;
    }
    return false;
  }
  return false;
};

export const getNumberOfContactStatus = (
  status: string,
  contactBatchCreations?: (ContactBatchCreation & {
    githubUrls: GithubUrl[];
  })[]
) => {
  if (!contactBatchCreations) return 0;
  return contactBatchCreations?.reduce((acc, curr) => {
    const urlsInProgressWaiting = curr.githubUrls.reduce((acc, curr) => {
      if (curr.status === status) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return acc + urlsInProgressWaiting;
  }, 0);
};

export function getRepoOrUser(router: NextRouter) {
  if (router.pathname.includes("repo")) {
    return "repo";
  } else {
    return "user";
  }
}

export function formatTimeEstimate(timeInSeconds: number) {
  if (timeInSeconds === undefined) return "0s";
  if (timeInSeconds < 60) {
    return `${timeInSeconds}s`;
  }
  const timeInMinutes = Math.round(timeInSeconds / 60);
  if (timeInMinutes < 60) {
    return `${timeInMinutes}m`;
  }
  const timeInHours = Math.round(timeInMinutes / 60);
  if (timeInHours < 24) {
    return `${timeInHours}h`;
  }
  const timeInDays = Math.round(timeInHours / 24);
  return `${timeInDays}d`;
}

export const getColorFromPercentage = (percentage: number) => {
  if (percentage < 40) return "bg-go";
  if (percentage < 70) return "bg-warn";
  else return "bg-stop";
};

export const getContributorRepos = (
  contactName: string,
  latestRepoEvaluation: RepoEvaluation
): string[] => {
  let repos: string[] = [];
  if (!latestRepoEvaluation) return repos;
  if (latestRepoEvaluation?.result) {
    const assignableUsers = latestRepoEvaluation.result.assignableUsers;
    if (!assignableUsers) return repos;
    for (const user of assignableUsers) {
      const newRepos: string[] =
        (user?.topRepositories ?? [])
          .filter((repo) => repo !== null)
          .filter((repo) => repo!.nameWithOwner !== contactName)
          .map((repo) => repo!.nameWithOwner) ?? [];
      repos = getUniqueArraySimple([...repos, ...newRepos]);
    }
  }
  return repos;
};

export const getSimplifiedStatus = (
  statusNumber: number | null | undefined
): number => {
  switch (statusNumber) {
    case 1:
    case 2:
    case 3:
    case 13:
      return 13;
    case 4:
    case 5:
    case 6:
    case 10:
    case 14:
      return 14;
    case 7:
    case 8:
    case 9:
    case 11:
    case 15:
      return 15;
    case 12:
      return 12;
    default:
      return 0;
  }
};
