export default function Input({
  value,
  setValue,
  placeholder,
  className = "",
  disabled = false,
  maxLength,
  onEnter
}: {
  value: string | undefined;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  maxLength?: number;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}) {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      onEnter(e);
    }
  };
  return <input onKeyDown={onKeyDown} type="text" onChange={e => setValue(e.target.value)} value={value ?? ""} aria-label={placeholder} className={`${className} w-full rounded-md border border-divider bg-offwhite px-4 py-2 text-base outline-none transition-all focus:ring focus:ring-accent-subtle placeholder:opacity-40`} placeholder={placeholder} disabled={disabled} maxLength={maxLength} data-sentry-component="Input" data-sentry-source-file="Input.tsx" />;
}