import { type ReactNode } from "react";
export function LayoutMain({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <main className="relative flex w-[calc(100%-680px)] h-[calc(100vh-12px)] overflow-y-hidden flex-1 flex-col items-center" data-sentry-component="LayoutMain" data-sentry-source-file="Layout.tsx">
      {children}
    </main>;
}
export function LayoutContent({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <div className="flex h-full overflow-y-scroll w-full justify-center z-0 px-8" data-sentry-component="LayoutContent" data-sentry-source-file="Layout.tsx">
      <div className="flex max-w-full grow flex-col justify-between">
        {children}
      </div>
    </div>;
}
export function TableLayoutContent({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <div className="flex h-[calc(100vh_-_36px)] overflow-y-hidden w-full justify-center z-0 px-8" data-sentry-component="TableLayoutContent" data-sentry-source-file="Layout.tsx">
      <div className="flex max-w-full grow flex-col justify-between">
        {children}
      </div>
    </div>;
}
export function LayoutBreadcrumbs({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  return <div className="flex-1 text-sm text-muted" data-sentry-component="LayoutBreadcrumbs" data-sentry-source-file="Layout.tsx">{children}</div>;
}
export function LayoutHeader({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  return <div className="top-50 sticky z-0 w-full bg-white px-8 pt-8" data-sentry-component="LayoutHeader" data-sentry-source-file="Layout.tsx">
      {children}
    </div>;
}
export function LayoutHeaderTop({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <div className="ml-auto flex content-center items-center pb-4" data-sentry-component="LayoutHeaderTop" data-sentry-source-file="Layout.tsx">
      {children}
    </div>;
}
export function LayoutHeaderMenu({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <ul className="flex w-full gap-4 border-b border-divider font-medium" data-sentry-component="LayoutHeaderMenu" data-sentry-source-file="Layout.tsx">
      {children}
    </ul>;
}
export function LayoutHeaderTitle({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <span className="flex-1 text-2xl font-semibold" data-sentry-component="LayoutHeaderTitle" data-sentry-source-file="Layout.tsx">{children}</span>;
}
export function LayoutHeaderTitleIcon({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <div className="mr-2 h-5 w-5" data-sentry-component="LayoutHeaderTitleIcon" data-sentry-source-file="Layout.tsx">{children}</div>;
}
export function LayoutHeaderActions({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <div className="flex gap-4" data-sentry-component="LayoutHeaderActions" data-sentry-source-file="Layout.tsx">{children}</div>;
}
export default function Layout({
  children
}: Readonly<{
  children: ReactNode | ReactNode[];
}>) {
  return <div className="flex  text-gray-700 dark:text-gray-300" data-sentry-component="Layout" data-sentry-source-file="Layout.tsx">{children}</div>;
}