import Head from "next/head";
import Layout from "~/components/layout/Layout";
import Image from "next/image";
export default function MobileScreen() {
  return <Layout data-sentry-element="Layout" data-sentry-component="MobileScreen" data-sentry-source-file="index.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>OpenQ DRM</title>
      </Head>

      <div className="flex-col py-40 text-center justify-center items-center">
        <div className="flex justify-center">
          <Image width="200" height="200" alt="openq-logo" src="/logo.png" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>

        <div className="flex mt-4 p-8">
          Please use a desktop device to access our DRM application.
        </div>
      </div>
    </Layout>;
}