import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { MarkGithubIcon } from "@primer/octicons-react";
import { signIn, useSession } from "next-auth/react";
import { useEffect } from "react";
import { useLogger } from "~/providers/LoggerProvider";
import Button from "../../base/Button";
export default function ConnectGithub() {
  const logger = useLogger();
  const {
    data: session
  } = useSession();
  useEffect(() => {
    if (session?.accessToken) {
      document.cookie = `github_oauth_token_unsigned=${session?.accessToken}; path=/;`;
    }
  }, [session?.accessToken]);
  function handleSignIn() {
    signIn("github").catch(err => logger.error(err, "ConnectGithub.tsx"));
  }
  return <Button onClick={handleSignIn} className="flex w-2/3 justify-center px-6 py-4 text-2xl font-bold" data-sentry-element="Button" data-sentry-component="ConnectGithub" data-sentry-source-file="ConnectGithub.tsx">
      <MarkGithubIcon className="mr-4 h-8 w-8" data-sentry-element="MarkGithubIcon" data-sentry-source-file="ConnectGithub.tsx" />
      Connect to GitHub
      <ArrowRightIcon className="ml-4 h-8 w-8" data-sentry-element="ArrowRightIcon" data-sentry-source-file="ConnectGithub.tsx" />
    </Button>;
}