import HeroPage from "../layout/HeroPage";
export default function FreeTrialExpired() {
  return <HeroPage data-sentry-element="HeroPage" data-sentry-component="FreeTrialExpired" data-sentry-source-file="FreeTrialExpired.tsx">
      <div className="flex h-screen w-1/2  flex-col content-center items-center justify-center">
        <div className="mb-3 text-3xl font-bold text-gray-600">
          OpenQ Closed Beta
        </div>
        <div className="text mb-10 max-w-[400px] text-center">
          It looks like your free trial has expired, please contact us at{" "}
          <a className="text-accent" href="mailto:info@openq.dev">
            info@openq.dev
          </a>{" "}
          to request an extension or upgrade.
        </div>
      </div>
    </HeroPage>;
}