import React, { createContext, useContext } from "react";
import { Logger } from "@openqlabs/utils";
import { useTeamAccountUser } from "./TeamAccountUserProvider";
import { env } from "~/env.mjs";
interface LoggerProviderProps {
  children: React.ReactNode;
}
const LoggerContext = createContext<Logger | null>(null);
export function useLogger(): Logger {
  return (useContext(LoggerContext) as Logger);
}
export function LoggerProvider({
  children
}: LoggerProviderProps) {
  const [teamAccountUser] = useTeamAccountUser();
  const userId = teamAccountUser?.id;
  const logger = new Logger((userId as string), env.NEXT_PUBLIC_LOG_DISABLED === "true");
  return <LoggerContext.Provider value={logger} data-sentry-element="unknown" data-sentry-component="LoggerProvider" data-sentry-source-file="LoggerProvider.tsx">{children}</LoggerContext.Provider>;
}