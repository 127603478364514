import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
export default function useInvite(setTeamAccounts: () => void) {
  const [teamAccountUser] = useTeamAccountUser();
  const [tryOnce, setTryOnce] = useState<boolean>(true);
  const router = useRouter();
  const {
    code
  } = router.query;
  const {
    mutate: acceptInvite
  } = api.teamAccount.joinTeam.useMutation({
    onSuccess: () => {
      setTeamAccounts();
      setTryOnce(false);
    }
  });
  useEffect(() => {
    if (code && tryOnce && !router.asPath.includes("verify-email") && teamAccountUser) {
      acceptInvite({
        code: (code as string)
      });
    }
  }, [code, acceptInvite, router, teamAccountUser, setTeamAccounts, tryOnce]);
}