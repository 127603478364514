import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { RocketIcon, Cross1Icon, InfoCircledIcon } from "@radix-ui/react-icons";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
export default function Notification({
  children,
  className = "",
  info = false,
  title = "",
  isLoading = false,
  id,
  version = 1
}: Readonly<{
  children: React.ReactNode;
  className?: string;
  info?: boolean;
  isLoading?: boolean;
  title?: string;
  id: string;
  version?: number;
}>) {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (title === "Cleanup Alert: Contacts Updated") {
      const cookieKey = `notification_${id}`;
      const storedVersion = Cookies.get(cookieKey);
      console.log(`Cookie ${cookieKey}:`, storedVersion); // Debug log
      if (storedVersion && parseInt(storedVersion) >= version) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  }, [id, version, title]);
  const handleClose = () => {
    setIsVisible(false);
    if (title === "Cleanup Alert: Contacts Updated") {
      const cookieKey = `notification_${id}`;
      Cookies.set(cookieKey, version.toString(), {
        expires: 365,
        path: "/"
      });
      console.log(`Set cookie ${cookieKey} to ${version}`); // Debug log
    }
  };
  if (!isVisible) return null;
  return <div className={`relative pb-3 ${className}`} data-sentry-component="Notification" data-sentry-source-file="Notification.tsx">
      <Alert data-sentry-element="Alert" data-sentry-source-file="Notification.tsx">
        {!info ? <RocketIcon className="h-4 w-4" /> : <InfoCircledIcon className="h-4 w-4" />}
        <AlertTitle data-sentry-element="AlertTitle" data-sentry-source-file="Notification.tsx">{title}</AlertTitle>
        <AlertDescription isLoading={isLoading} data-sentry-element="AlertDescription" data-sentry-source-file="Notification.tsx">
          <div className="pr-8 pb-1">{children}</div>
        </AlertDescription>
        <div>
          <button className="absolute top-2 right-2 p-1 rounded-full hover:bg-gray-100" onClick={handleClose}>
            <Cross1Icon className="h-4 w-4" data-sentry-element="Cross1Icon" data-sentry-source-file="Notification.tsx" />
          </button>
        </div>
      </Alert>
    </div>;
}